import './App.css';
import React from 'react';
import '../src/scss/app-loader.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Profile from './views/Profile';

function App() {

  return (
    <React.StrictMode>
      <Router>
        <Switch>
          <Route
            exact
            path='/join'
            render={() => {
              return <Profile />
            }}
          />
        </Switch>
      </Router>
      {/* <RouterProvider router={router} /> */}
    </React.StrictMode>
  );
}

export default App;
