import './styles.css';
// ** Reactstrap Imports
import { Card, CardBody, CardTitle, CardText, Button } from 'reactstrap'

import { isAndroid, isIOS } from 'react-device-detect'
import LogoHeadr from '../components/LogoHeadr'

const Profile = () => {

  const openAppleStore = () => {
    if (isIOS) {
      window.location.href = 'https://apps.apple.com/us/app/beyouid/id1544870510';
    }
  }
  const openPlayStore = () => {
    if (isAndroid) {
      window.location.href = "market://details?id=com.beyouid.app";
    }
  }
  const learnMore = () => {
    window.location.href = "https://beyouid.com";
  }

  function GetURLParameter() {
    const sPageURL = window.location.href;
    const queryStringIndex = sPageURL.indexOf('?');
    if (queryStringIndex !== -1) {
         const queryString = sPageURL.substring(queryStringIndex + 1);
         const queryParams = queryString.split('&');
         for (const param of queryParams) {
              const keyValue = param.split('=');
              if (keyValue[0] === "auth") {
                   const code = keyValue[1];
                   return code;
              }
         }
    }
}

  return (

    <div id="geeks" className="center">
      <div className="v_card mblcontainer p-10"  >
        <Card className='mb-0'>
          <CardBody>
            <LogoHeadr back={false}/>
            <div className='heading mb-1'>
              Welcome
            </div>
            <CardText className=''>
            If you haven't installed our app yet, no worries! Just click the link below to download and install it. If you already have the app, click 'OPEN' above or launch the BeYouID App. After registering, go to the teams page and click “join a team” and enter the team code <u><b>{GetURLParameter()}</b></u>.</CardText>
            <div className='d-flex flex-column align-items-center my-5' style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <CardText className='cursor-pointer' onClick={openAppleStore}>
                <img src={'images/app-store-apple.png'} width={150} alt='Not authorized page' />
              </CardText>
            </div>
            <div className='d-flex flex-column align-items-center my-5' style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
              <CardText className='cursor-pointer' onClick={openPlayStore}>
                <img src={'images/app-store-google.png'} width={150} alt='Not authorized page' />
              </CardText>
            </div>

            <CardText className='mt-5 text-center'>
              Want to learn more about BeYouID?
            </CardText>
            <button className="btn" onClick={learnMore}>
              <span className="title" style={{ fontSize: 16, color: 'white' }}>Learn More</span>
            </button>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default Profile
